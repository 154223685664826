<template>
  <div>
    <el-row
      class="row_item"
      v-for="item in curTemplate"
      :key="item.prop"
      type="flex">
      <div class="text_color_info" style="width:60px;">
        问题{{ item.prop }}:
      </div>
      <div style="width:460px;">
        <p class="text_color_info tw-mb-15px">{{ item.label }}</p>
        <el-input
          type="textarea" maxlength="300" show-word-limit
          autosize
          placeholder="请输入内容"
          v-model="item.value">
        </el-input>
      </div>
    </el-row>
  </div>
</template>

<script>
import t1 from './json/question-template-1.json'
import t2 from './json/question-template-2.json'
export default {
  props: {
    // 选择哪个模板
    templateIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      templates: [t1, t2]
    }
  },
  computed: {
    // 当前问题模板
    curTemplate () {
      return this.templates[this.templateIndex]
    }
  },
  methods: {
    getTemplate () {
      return this.curTemplate
    }
  }
}
</script>

<style lang="scss" scoped>
  .row_item {
    width: 520px;
    margin-bottom: 22px;
  }
</style>
