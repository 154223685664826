<!-- 审核需求 -->
<template>
  <div class="detail">
    <detail-header title="需求详情"></detail-header>
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <info-header title="基本信息"></info-header>
        <div class="tw-py-20px">
          <info-item
            :label-width="labelWidth"
            label="需求名称"
            :value="formData.title">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="需求类别"
            :value="formData.serviceTypeName">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="行业领域"
            :value="formData.industryName.toString()">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="技术领域"
            :value="formData.technicalName.toString()">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="需求描述"
            :value="formData.description">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="关键词"
            :value="formData.keywords">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="预算金额"
            :value="formData.budget">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="需求所属企业名称"
            :value="formData.enterpriseName">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="统一社会信用代码"
            :value="formData.socialCreditCode">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="企业所在区域"
            :value="formData.province">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系人"
            :value="formData.contacts">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系手机"
            :value="formData.contactMobile">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系邮箱"
            :value="formData.contactEmail">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="时限要求">
            {{ formData.startAt }} - {{ formData.endAt }}
          </info-item>
          <el-row>
            <el-button
              type="primary"
              size="small"
              :loading="btnLoading"
              @click="onRelease">
              发布需求
            </el-button>
            <el-button
              type="primary"
              size="small"
              plain
              @click="onAllocate">
              分配需求
            </el-button>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 分配需求弹窗 -->
    <allot-require
      :visible.sync="allotRequireDialogVisible"
      :requireId="id">
    </allot-require>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
import allotRequire from './components/allot-require/index.vue'
export default {
  components: {
    allotRequire
  },
  data () {
    return {
      loading: false,
      labelWidth: '170px',
      btnLoading: false,
      allotRequireDialogVisible: false, // 分配需求弹窗
      formData: {
        title: '', // 需求名称
        serviceTypeId: '', // 需求类别
        serviceTypeName: '',
        industryId: '', // 行业领域
        industryName: '',
        technicalId: '', // 技术领域
        technicalName: '', //
        description: '', // 需求描述
        keywords: '', // 关键词
        budget: '', // 预算金额
        enterpriseName: '', // 需求所属企业名称
        socialCreditCode: '', // 统一社会信用代码
        province: '', // 企业所在区域
        contacts: '', // 联系人
        contactMobile: '', // 联系手机
        contactEmail: '', // 联系邮箱
        startAt: '', // 时限要求
        endAt: '', // 时限要求
        logicStatus: -1
      },
      rules: {
        remarks: [
          { required: true, message: '请填写客服备注', trigger: ['change', 'blur'] }
        ],
        userId: [
          { required: true, message: '请选择对接用户', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    globalVar () {
      return globalVar
    },
    canReview () {
      // 限制下需求在其他状态不可发布或分配
      return this.formData.logicStatus === globalVar.REQUIRE_STATUS_SUBMITTED
    }
  },
  mounted () {
    this.getRequireDetail()
  },
  methods: {
    // 获取需求详情
    getRequireDetail () {
      this.loading = true
      api.getRequireDetail(this.id).then(res => {
        if (res.data.code === 0) {
          for (const key in this.formData) {
            if (Object.hasOwnProperty.call(this.formData, key)) {
              this.formData[key] = res.data.data[key]
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 直接发布
    onRelease () {
      this.$confirm('确认后将发布到资源大厅，请确认你的操作！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.btnLoading = true
        api.releaseRequirement(this.id).then(res => {
          if (res.data.code === 0) {
            this.$message.success('操作成功')
            this.$router.replace('/resource-require/require')
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.btnLoading = false
        })
      }).catch(() => {
      })
    },
    // 打开分配的弹窗
    onAllocate () {
      this.allotRequireDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;
    }
  }
}
</style>
