<template>
  <el-form
    ref="elForm"
    :model="form"
    :rules="rules"
    size="small"
    label-width="110px"
    label-position="left">
    <el-form-item
      label="选择对接用户"
      prop="userId">
      <div class="tw-flex tw-justify-between">
        <el-select
          v-model="form.role"
          style="width:200px;"
          @change="onRoleChange"
          placeholder="请选择角色">
          <el-option
            v-for="(item, index) in roleOptions"
            :key="index"
            :value="item.value"
            :label="item.label">
          </el-option>
        </el-select>
        <el-select
          v-model="form.userId"
          filterable
          :disabled="!form.role"
          style="width:200px;"
          placeholder="请选择用户">
          <el-option
            v-for="item in userOptions"
            :key="item.id"
            :label="item.organizationName + item.username"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      form: {
        role: '',
        userId: ''
      },
      rules: {
        userId: [
          { required: true, message: '请选择对接用户', trigger: ['change', 'blur'] }
        ]
      },
      roleOptions: [
        { label: '服务用户', value: globalVar.USER_ROLE_1 },
        { label: '服务集成商', value: globalVar.USER_ROLE_2 }
      ],
      userOptions: []
    }
  },
  methods: {
    onRoleChange () {
      this.form.userId = ''
      this.getUserList(this.form.role)
    },
    getUserList (role) {
      api.getUserListByRole({
        role
      }).then(res => {
        if (res.data.code === 0) {
          this.userOptions = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 对表单进行验证并取值
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.elForm.validate()
          .then(res => {
            resolve(this.form)
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  }
}
</script>

<style scoped>
</style>
