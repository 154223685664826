<template>
  <el-dialog
    custom-class="my-el-dialog"
    :title="curStep === 3 ? '客服备注' : '分配需求'"
    :visible.sync="$_visible"
    width="600px"
    :close-on-click-modal="false"
    append-to-body>
    <div class="dialog-max-height tw--mx-20px tw-px-40px">
      <template v-if="curStep === 1">
        <p class=" text_color_info">是否需要上传诊断报告？</p>
      </template>
      <template v-if="curStep === 2">
        <select-user
          ref="selectUser"
          v-if="!isNeed">
        </select-user>
        <select-question
          ref="selectQuestion"
          v-else>
        </select-question>
      </template>
      <template v-if="curStep === 3">
        <question-form
          ref="questionForm"
          :templateIndex="questionForm.template">
        </question-form>
      </template>
    </div>
    <div slot="footer" class="footer">
      <template v-if="curStep === 1">
        <el-button
          size="small"
          @click="closeDialog">
          取消
        </el-button>
        <el-button
          size="small"
          type="primary"
          plain
          @click="onNeed(false)">
          不需要
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="onNeed(true)">
          需要
        </el-button>
      </template>
      <template v-else>
        <el-button
          size="small"
          type="primary"
          plain
          @click="onLast">
          上一步
        </el-button>
        <el-button
          size="small"
          type="primary"
          :loading="btnLoading"
          @click="onConfirm">
          确认
        </el-button>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import * as api from '@/api'
import selectUser from './select-user.vue'
import selectQuestion from './select-question.vue'
import questionForm from './question-form.vue'
export default {
  components: {
    selectUser,
    selectQuestion,
    questionForm
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    // 需求Id
    requireId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      btnLoading: false,
      // 当前步骤
      curStep: 1,
      // 是否需要上传诊断报告
      isNeed: false,
      userForm: {},
      questionForm: {}
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  methods: {
    // 选择需要与否
    onNeed (bol) {
      if (bol) {
        this.isNeed = true
      }
      this.curStep++
    },
    // 上一步
    onLast () {
      if (this.isNeed) {
        this.isNeed = false
      }
      this.curStep--
    },
    // 确认提交
    onConfirm () {
      if (this.curStep === 2) {
        if (this.isNeed) {
          this.getSelectQuestionForm()
        } else {
          this.getSelectUserForm()
        }
      } else if (this.curStep === 3) {
        this.getQuestionTemplate()
      }
    },
    // 获取选择用户的表值
    async getSelectUserForm () {
      try {
        const userForm = await this.$refs.selectUser.validate()
        if (userForm) {
          this.userForm = userForm
          this.onAllocate(this.userForm)
        }
      } catch (err) {
        console.log(err)
        return false
      }
    },
    // 获取选择问题的表值
    async getSelectQuestionForm () {
      try {
        const questionForm = await this.$refs.selectQuestion.validate()
        if (questionForm) {
          this.questionForm = questionForm
          this.curStep++
        }
      } catch (err) {
        console.log(err)
        return false
      }
    },
    // 获取填写好的问题模板
    getQuestionTemplate () {
      const getTemplate = this.$refs.questionForm.getTemplate()
      const data = {
        userId: this.questionForm.userId,
        questions: getTemplate
      }
      // 进行分配
      this.onAllocate(data)
    },
    // 分配需求
    onAllocate (data) {
      this.btnLoading = true
      api.allocateRequirement(this.requireId, {
        reportFlag: this.isNeed ? 1 : 0, // 是否需要诊断包告
        ...data
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.closeDialog()
          this.$router.replace('/resource-require/require')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.btnLoading = false
      })
    },
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .dialog-max-height {
    max-height: 500px;
    overflow: auto;
  }
</style>
