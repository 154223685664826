<template>
  <el-form
    ref="elForm"
    :model="form"
    :rules="rules"
    size="small"
    label-width="120px"
    label-position="left">
    <el-form-item
      label="选择对接用户"
      prop="userId">
      <el-select
        v-model="form.userId"
        filterable
        style="width:400px;"
        placeholder="请选择服务集成商">
        <el-option
          v-for="item in userOptions"
          :key="item.id"
          :label="item.organizationName + item.username"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      label="选择问题模板"
      prop="template">
      <el-select
        v-model="form.template"
        filterable
        style="width:400px;"
        placeholder="请选择服务集成商">
        <el-option
          v-for="item in templateOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      form: {
        userId: '',
        template: ''
      },
      rules: {
        userId: [
          { required: true, message: '请选择对接用户', trigger: ['change', 'blur'] }
        ],
        template: [
          { required: true, message: '请选择问题模板', trigger: ['change', 'blur'] }
        ]
      },
      userOptions: [],
      templateOptions: [
        {
          label: '成果转化相关问题提纲',
          value: 0
        },
        {
          label: '需求相关问题提纲',
          value: 1
        }
      ]
    }
  },
  created () {
    // 获取服务集成商
    this.getUserList()
  },
  methods: {
    getUserList (role) {
      api.getUserListByRole({
        role: globalVar.USER_ROLE_2
      }).then(res => {
        if (res.data.code === 0) {
          this.userOptions = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 对表单进行验证并取值
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.elForm.validate()
          .then(res => {
            resolve(this.form)
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  }
}
</script>

<style scoped>
</style>
